<div class="close-btn">
  <button [disabled]="working" (click)="close()">
    <i class="fa-solid fa-xmark fa-xl"></i>
  </button>
</div>

<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchDefault>
    <h2 mat-dialog-title class="response-title">
      <div [ngClass]="'checkmark' + (data.iconStyle ? '-' + data.iconStyle : '')">
        <i class="fa-solid fa-xl" [ngClass]="'fa-' + (data.icon ?? 'triangle-exclamation')"></i>
      </div>
      {{ data.header ?? 'Please confirm' }}
    </h2>
    <div class="initial-content">
      <mat-dialog-content>
        <div [innerHTML]="data.content"></div>
      </mat-dialog-content>

      <mat-dialog-actions class="actions">
        <lseg-aurora-button [disabled]="working" (click)="close()" [fullWidth]="'auto'" accent="secondary">Cancel</lseg-aurora-button>
        <lseg-aurora-button [disabled]="working" (click)="confirm()" [fullWidth]="'auto'">{{
          data.confirm ?? 'Confirm'
        }}</lseg-aurora-button>
      </mat-dialog-actions>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'success'">
    <h2 mat-dialog-title class="response-title">
      <div class="checkmark"><i class="fa-solid fa-check-circle fa-xl"></i></div>
      Successfull state
    </h2>

    <div class="status-content">
      <mat-dialog-content>
        <div [innerHTML]="data.content"></div>
      </mat-dialog-content>

      <mat-dialog-actions class="response-actions">
        <lseg-aurora-button (click)="close()" [fullWidth]="'always'">Close</lseg-aurora-button>
      </mat-dialog-actions>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'loading'">
    <h2 mat-dialog-title class="response-title">Loading state</h2>

    <div class="status-content loading-content">
      <mat-dialog-content class="loading-state">
        <lseg-spinner-loader></lseg-spinner-loader>
      </mat-dialog-content>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'error'">
    <h2 mat-dialog-title class="response-title">
      <div class="checkmark-error"><i class="fa-solid fa-circle-xmark fa-xl"></i></div>

      An Error Occurred
    </h2>

    <div class="status-content">
      <mat-dialog-content>
        <div [innerHTML]="data.content"></div>
      </mat-dialog-content>

      <mat-dialog-actions class="response-actions">
        <lseg-aurora-button (click)="close()" [fullWidth]="'always'">Close</lseg-aurora-button>
      </mat-dialog-actions>
    </div>
  </ng-container>
</ng-container>
