import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from './environments/environment';
const oktaAuth = new OktaAuth({
  clientId: environment.oktaInfo.oktaConfig.clientId,
  issuer: environment.oktaInfo.oktaConfig.issuer,
  redirectUri: environment.oktaInfo.oktaConfig.redirectUri,
  scopes: environment.oktaInfo.oktaConfig.scopes,
  pkce: environment.oktaInfo.oktaConfig.pkce,
});

export const oktaConfig = {
  oktaAuth,
};
