<div class="section-grid-container toolbar-panel">
  <lseg-search-input
    placeHolder="Search for title"
    [minLength]="2"
    [autocomplete]="false"
    (changed)="changeTitle($event)"
    (keyboardEnter)="applySearch()"
    [autocomplete]="false"
    [value]="value?.title ?? ''"
    fullWidth="onTabletAndBelow"
    label="Title"
  ></lseg-search-input>
  <lseg-dropdown-select-menu
    *ngIf="isAdmin"
    [multiple]="false"
    [selectOptions]="statusOptions"
    (optionEvent)="changeStatus($event)"
    [value]="value?.status ?? '-ANY-'"
    [minWidth]="160"
    fullWidth="onTabletAndBelow"
    label="Status"
  ></lseg-dropdown-select-menu>
  <lseg-date-picker
    fullWidth="onTabletAndBelow"
    [showLabel]="true"
    [allowEmpty]="true"
    (dateChange)="changeDate($event)"
    [allowRange]="false"
    [minWidth]="240"
    label="Created date"
  ></lseg-date-picker>
  <lseg-dropdown-select-menu
    [multiple]="false"
    [selectOptions]="marketOptions"
    (optionEvent)="changeMarket($event)"
    [value]="value?.market ?? 'all'"
    [minWidth]="260"
    fullWidth="onTabletAndBelow"
    label="Market"
  ></lseg-dropdown-select-menu>
  <div class="apply">
    <lseg-aurora-button [minWidth]="100" fullWidth="onTabletAndBelow" accent="secondary" (buttonClick)="applySearch()">
      Apply
    </lseg-aurora-button>
  </div>
</div>
