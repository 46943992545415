import { NavigationItem } from '@issuerservices/design-system/lib/components/app-layout/common/navigation/navigation.types';

export const userMenu: NavigationItem[] = [
  {
    type: 'basic',
    title: 'System status',
    exactMatch: true,
    link: '/',
  },
  {
    type: 'basic',
    title: 'Contact Us',
    link: '/contact-us',
  },
  {
    type: 'basic',
    title: 'Weekend activity monitor',
    link: '/wam',
  },
  {
    type: 'basic',
    title: 'Archive',
    link: '/archive',
  },
];

export const adminMenu: NavigationItem[] = [
  ...userMenu,
  {
    type: 'basic',
    title: 'Incidents',
    link: '/admin/incidents',
  },
  {
    type: 'basic',
    title: 'Markets',
    link: '/admin/markets',
  },
  {
    type: 'basic',
    title: 'People',
    link: '/admin/people',
  },
  {
    type: 'basic',
    title: 'Templates',
    link: '/admin/templates',
  },
  {
    type: 'basic',
    title: 'WAM',
    link: '/admin/wams',
  },
];
