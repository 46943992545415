import { RuntimeChecks } from '@ngrx/store';

export const storeConfig: RuntimeChecks = {
  strictActionImmutability: true,
  strictStateImmutability: true,
  strictActionTypeUniqueness: true,
  strictStateSerializability: false,
  strictActionSerializability: false,
  strictActionWithinNgZone: false,
};
