import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { WamEntity } from '../../wam/interfaces/wam.interface';
import { WamRequest } from '../interfaces/wam.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminWamApiService {
  constructor(private http: HttpClient) {}

  allWams(): Observable<ResponseEntity<WamEntity[]>> {
    return this.http.get<ResponseEntity<WamEntity[]>>(`${environment.api.wam}/activities`);
  }

  createWam(entity: WamRequest): Observable<ResponseEntity<WamEntity>> {
    return this.http.post<ResponseEntity<WamEntity>>(`${environment.api.wam}/save`, entity);
  }

  updateWam(entity: WamRequest): Observable<ResponseEntity<WamEntity>> {
    return this.http.put<ResponseEntity<WamEntity>>(`${environment.api.wam}/update`, entity);
  }

  getWam(templateId: string): Observable<ResponseEntity<WamEntity>> {
    return this.http.get<ResponseEntity<WamEntity>>(`${environment.api.wam}/${templateId}`);
  }

  removeWam(templateId: string): Observable<ResponseEntity<WamEntity>> {
    return this.http.delete<ResponseEntity<WamEntity>>(`${environment.api.wam}/delete/${templateId}`);
  }
}
