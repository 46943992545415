import { adminMenu, userMenu } from 'src/environments/menu';
import { EnvConfig } from '../env-config';

const applicationDomain = 'execute-api.eu-west-2.amazonaws.com';
const domainPrefix = '5csmw0cih1';

export const environment: EnvConfig = {
  env: 'dev',
  api: {
    wam: `https://${domainPrefix}.${applicationDomain}/wam/api`,
    dynamo: `https://${domainPrefix}.${applicationDomain}`,
    peoples: `https://${domainPrefix}.${applicationDomain}/peoples/api`,
  },
  msalConfig: {
    uri: [
      `https://${domainPrefix}.${applicationDomain}/*/create`,
      `https://${domainPrefix}.${applicationDomain}/*/save`,
      `https://${domainPrefix}.${applicationDomain}/*/update`,
      `https://${domainPrefix}.${applicationDomain}/*/delete`,
      `https://${domainPrefix}.${applicationDomain}/*/delete/*`,
      `https://${domainPrefix}.${applicationDomain}/incident/archive/*`,
      `https://${domainPrefix}.${applicationDomain}/market/get/*`,
      `https://${domainPrefix}.${applicationDomain}/template/market/*`,
      `https://${domainPrefix}.${applicationDomain}/template/get/*`,
      `https://${domainPrefix}.${applicationDomain}/template/active`,
      `https://${domainPrefix}.${applicationDomain}/incident/active`,
      `https://${domainPrefix}.${applicationDomain}/wam/api/activities`,
      `https://${domainPrefix}.${applicationDomain}/wam/api/save`,
      `https://${domainPrefix}.${applicationDomain}/wam/api/update`,
      `https://${domainPrefix}.${applicationDomain}/wam/api/delete/*`,
      `https://${domainPrefix}.${applicationDomain}/*/account/cancel?sso=true*`,
    ],
    scopes: ['user.read', 'email', 'profile', 'openid'],
    clientId: 'adda97b5-61fc-4227-97b9-ad6b9657c9d6',
    authority: 'https://login.microsoftonline.com/287e9f0e-91ec-4cf0-b7a4-c63898072181',
    redirectUrl: '/',
  },
  oktaInfo: {
    production: false,
    oktaConfig: {
      clientId: '0oa252qn4xoRYrt0C0h8',
      issuer: 'https://dev-identity.lseg.com/oauth2/aus25ajkapzS9u6Ee0h8',
      redirectUri: window.location.origin + '/callback',
      scopes: ['openid', 'profile', 'email'],
      pkce: true,
      profileUrl: '/peoples/api/user',
      deleteUrl: 'confirm/account/cancel',
      cancelUrl: '/account/cancel',
    },
  },
  userMenu,
  adminMenu,
};
