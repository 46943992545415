import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lsp-data-protection-requirements',
  templateUrl: './data-protection-requirements.component.html',
  standalone: true,
})
export class DataProtectionRequirementsComponent {}
