import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import {
  IncidentCreateUpdateRequest,
  IncidentFilterableRequest,
  IncidentRequest,
  SubIncidentCreateRequest,
  SubIncidentDeleteRequest,
} from 'src/app/interfaces/reducer.interface';
import { IncidentsResponse, ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { IncidentEntity } from '../interfaces/incident.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminIncidentApiService {
  constructor(private http: HttpClient) {}

  allIncidents(request: IncidentFilterableRequest): Observable<ResponseEntity<IncidentsResponse>> {
    return this.http.post<ResponseEntity<IncidentsResponse>>(`${environment.api.dynamo}/incident/active`, request);
  }

  createIncident(entity: IncidentCreateUpdateRequest): Observable<ResponseEntity<IncidentEntity>> {
    return this.http.post<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/create`, entity);
  }

  createSubIncident(request: SubIncidentCreateRequest[]): Observable<ResponseEntity<IncidentEntity>[]> {
    let calls: Observable<ResponseEntity<IncidentEntity>>[] = [];
    request.forEach((el) =>
      calls.push(this.http.post<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/sub/create`, el))
    );
    return forkJoin(calls);
  }

  updateIncident(entity: IncidentCreateUpdateRequest): Observable<ResponseEntity<IncidentEntity>> {
    return this.http.put<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/update`, entity);
  }

  getIncident(request: IncidentRequest): Observable<ResponseEntity<IncidentEntity>> {
    return this.http.get<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/get/${request.id}/${request.market}`);
  }

  removeIncident(request: IncidentRequest): Observable<ResponseEntity<IncidentEntity>> {
    return this.http.delete<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/delete/${request.id}/${request.market}`);
  }

  removeSubIncident(request: SubIncidentDeleteRequest[]): Observable<ResponseEntity<IncidentEntity>[]> {
    let calls: Observable<ResponseEntity<IncidentEntity>>[] = [];
    request.forEach((el) =>
      calls.push(this.http.delete<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/sub/delete`, { body: el }))
    );
    return forkJoin(calls);
  }

  archiveIncident(request: IncidentRequest): Observable<ResponseEntity<IncidentEntity>> {
    return this.http.put<ResponseEntity<IncidentEntity>>(`${environment.api.dynamo}/incident/archive/${request.id}/${request.market}`, {});
  }
}
