import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageableRequest, TemplateRequest } from 'src/app/interfaces/reducer.interface';
import { ResponseEntity, TemplatesResponse } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { MarketTemplate, TemplateEntity } from '../interfaces/template.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminTemplateApiService {
  constructor(private http: HttpClient) {}

  templateByMarket(market: string): Observable<ResponseEntity<MarketTemplate[]>> {
    return this.http.get<ResponseEntity<MarketTemplate[]>>(`${environment.api.dynamo}/template/market/${market}`);
  }

  allTemplates(request: PageableRequest): Observable<ResponseEntity<TemplatesResponse>> {
    return this.http.post<ResponseEntity<TemplatesResponse>>(`${environment.api.dynamo}/template/active`, request);
  }

  createTemplate(entity: TemplateRequest): Observable<ResponseEntity<TemplateEntity>> {
    return this.http.post<ResponseEntity<TemplateEntity>>(`${environment.api.dynamo}/template/create`, entity);
  }

  updateTemplate(entity: TemplateRequest): Observable<ResponseEntity<TemplateEntity>> {
    return this.http.put<ResponseEntity<TemplateEntity>>(`${environment.api.dynamo}/template/update`, entity);
  }

  getTemplate(templateId: string): Observable<ResponseEntity<TemplateEntity>> {
    return this.http.get<ResponseEntity<TemplateEntity>>(`${environment.api.dynamo}/template/get/${templateId}`);
  }

  removeTemplate(templateId: string): Observable<ResponseEntity<TemplateEntity>> {
    return this.http.delete<ResponseEntity<TemplateEntity>>(`${environment.api.dynamo}/template/delete/${templateId}`);
  }
}
