import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MarketRequest } from 'src/app/interfaces/reducer.interface';
import { ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { MarketResponse } from '../interfaces/market.interface';

@Injectable({
  providedIn: 'root',
})
export class AdminMarketApiService {
  constructor(private http: HttpClient) {}

  markets() {
    return this.http.get<ResponseEntity<MarketResponse[]>>(`${environment.api.dynamo}/market/active`);
  }

  createMarket(entity: MarketRequest): Observable<ResponseEntity<MarketResponse>> {
    return this.http.post<ResponseEntity<MarketResponse>>(`${environment.api.dynamo}/market/create`, entity);
  }

  updateMarket(entity: MarketRequest): Observable<ResponseEntity<MarketResponse>> {
    return this.http.put<ResponseEntity<MarketResponse>>(`${environment.api.dynamo}/market/update`, entity);
  }

  getMarket(marketId: string): Observable<ResponseEntity<MarketResponse>> {
    return this.http.get<ResponseEntity<MarketResponse>>(`${environment.api.dynamo}/market/get/${marketId}`);
  }

  removeMarket(marketId: string): Observable<ResponseEntity<MarketResponse>> {
    return this.http.delete<ResponseEntity<MarketResponse>>(`${environment.api.dynamo}/market/delete/${marketId}`);
  }
}
