import { PageableRequest } from '../interfaces/reducer.interface';

export function pageRequest(
  lastEvaluatedKey: [string, string],
  lastEvaluatedIndex: [number, number],
  pageSize: number,
  currentPage: [number, number],
  nextPage: number,
  isPrevConditionChanged: boolean
): PageableRequest {
  const result: PageableRequest = {
    lastEvaluatedKey: lastEvaluatedKey[1],
    lastEvaluatedIndex: lastEvaluatedIndex[1],
    pageSize,
    currentPageNumber: currentPage[1],
    nextPageNumber: nextPage,
  };
  // run as initial load
  if (isPrevConditionChanged || nextPage === 1) {
    result.currentPageNumber = 0;
    result.nextPageNumber = 1;
    result.lastEvaluatedIndex = 0;
    result.lastEvaluatedKey = null;
  } else if (result.currentPageNumber == result.nextPageNumber) {
    // reload page
    result.lastEvaluatedKey = lastEvaluatedKey[0];
    result.lastEvaluatedIndex = lastEvaluatedIndex[0];
    result.currentPageNumber = currentPage[0];
  }
  return result;
}
