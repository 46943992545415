import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { systemStatusDetails } from 'src/app/store/actions/app.actions';
import { State } from 'src/app/store/reducers/app.reducer';
import { selectSelectedIncident } from 'src/app/store/selectors/app.selectors';
import { IncidentResponse } from '../../interfaces/status.interface';

@Component({
  selector: 'lsp-status-details',
  templateUrl: './status-details.component.html',
  styleUrls: ['./status-details.component.scss'],
  imports: [NgIf, NgFor, AsyncPipe, MatProgressSpinnerModule],
  standalone: true,
})
export class StatusDetailsComponent {
  incident$: Observable<IncidentResponse> = this._store.select(selectSelectedIncident);

  constructor(
    private readonly _store: Store<State>,
    private readonly _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initialLoad();
  }

  initialLoad(): void {
    const params = this._route.snapshot.params;
    this._store.dispatch(systemStatusDetails({ id: params['id'], market: params['market'] }));
  }
}
