import { Component, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterOutlet } from '@angular/router';
import { AuroraActionSize } from '@issuerservices/design-system';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { OktaAuthStateService } from '@okta/okta-angular';
import { State } from 'src/app/store/reducers/app.reducer';
import { loginFailureWithOkta, loginSuccessWithOkta } from './store/actions/app.actions';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, MatDialogModule],
})
export class AppComponent implements OnInit {
  title = 'lsp-ui';
  size: AuroraActionSize = 'big';
  constructor(
    private readonly _store: Store<State>,
    private readonly authStateService: OktaAuthStateService
  ) {}
  ngOnInit(): void {
    this.checkForLoginViaOkta();
  }

  checkForLoginViaOkta(): void {
    this.authStateService.authState$.pipe(untilDestroyed(this)).subscribe((authState) => {
      if (authState.isAuthenticated) {
        const accessToken = authState.accessToken?.accessToken;
        const email = authState.idToken?.claims.email;
        if (accessToken) {
          this._store.dispatch(loginSuccessWithOkta({ token: accessToken, loggedInUserId: email }));
        } else {
          this._store.dispatch(loginFailureWithOkta({ error: 'No token found' }));
        }
      }
    });
  }
}
