import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AuroraButtonModule,
  DatePickerModule,
  DropdownSelectMenuModule,
  IDropdownSelectOption,
  SearchInputModule,
} from '@issuerservices/design-system';
import { IncidentFilter } from 'src/app/interfaces/reducer.interface';
import { statuses } from 'src/environments/constants';

@Component({
  selector: 'lsp-incidents-filter',
  standalone: true,
  imports: [SearchInputModule, DropdownSelectMenuModule, AuroraButtonModule, DatePickerModule, NgIf],
  templateUrl: './incidents-filter.component.html',
  styleUrls: ['./incidents-filter.component.scss'],
})
export class IncidentsFilterComponent implements OnChanges {
  @Input() marketOptions: IDropdownSelectOption[];
  @Input() value: IncidentFilter;
  @Input() isAdmin = false;
  @Output() filter = new EventEmitter<IncidentFilter>();

  statusOptions = [{ key: '-ANY-' }, ...statuses];

  title: string;
  date: string;
  market: string;
  status: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && changes['value'].currentValue) {
      const filter = changes['value'].currentValue as IncidentFilter;
      this.title = filter.title;
      this.date = filter.date;
      this.market = filter.market;
      this.status = filter.status;
    }
  }

  changeTitle(value: unknown): void {
    this.title = value.toString();
  }

  changeDate(value: Date | { start: Date; end: Date }): void {
    const date = value as Date;
    this.date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
  }

  changeMarket(value: unknown): void {
    const { select } = value as { select: string };
    this.market = select;
  }

  changeStatus(value: unknown): void {
    const { select } = value as { select: string };
    this.status = select;
  }

  applySearch(): void {
    this.filter.emit({
      title: this.title === '' ? null : this.title,
      date: !this.date || this.date === '' ? null : this.date,
      market: this.market && this.market !== 'all' ? this.market : null,
      status: this.status && this.status !== '-ANY-' ? this.status : null,
    });
  }
}
