<div class="section-grid-container">
  <div class="single-content-wrapper">
    <h1>Terms and Conditions</h1>
    <div>
      This website&nbsp;<a href="https://liveservice.lseg.com">https://liveservice.lseg.com</a> (the “Website”), is a website of London
      Stock Exchange Group plc, a company registered in England &amp; Wales with Company Number 05369106, whose registered address is 10
      Paternoster Square, London EC4M 7LS (the “Exchange” or “We”). These terms and conditions (the “Terms”) govern your access to and use
      of this Service (as defined below). Please read these Terms carefully. By continuing to access and use this Service (as defined below)
      you are deemed to have understood and agreed to the Terms.
    </div>
    <div>
      <h3>1. SERVICE</h3>
    </div>
    <div>
      This Website will allow clients to request both email and/or SMS notifications when the Exchange experiences technical difficulties
      across the specified live markets and services (the “Service”). Further updates during any live service accident will be provided on
      the Website as and when necessary. This Website, its content and any emails or SMS sent as part of the Service may contain text, data,
      graphics, photographs, illustrations, artwork, names, logos, trade marks, service marks and information connected with London Stock
      Exchange Group plc (“Information”). The Exchange attempts to ensure Information is accurate, however Information is provided “AS IS”
      and on an “AS AVAILABLE” basis and may not be accurate, complete or up to date. Information on this Website may or may not have been
      prepared by the Exchange but is made available without responsibility on the part of the Exchange. The Exchange does not guarantee the
      accuracy, timeliness, completeness, performance or fitness for a particular purpose of the Service or any of the Information. No
      responsibility is accepted by or on behalf of the Exchange for any errors, omissions, or inaccurate Information in connection with the
      Service. No action should be taken or omitted to be taken in reliance upon Information in connection with this Service. We accept no
      liability for the results of any action taken on the basis of the Information.
    </div>
    <div>
      <h3>2. LIABILITY</h3>
    </div>
    <div>
      All implied warranties, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose,
      non-infringement, compatibility, security and accuracy are excluded from these Terms to the extent that they may be excluded as a
      matter of law. Further, the Exchange does not warrant that the Service will be uninterrupted or error free or that any defects will be
      corrected. To the extent permitted by applicable law, the Exchange expressly disclaims all liability howsoever arising whether in
      contract, tort (or deceit) or otherwise (including, but not limited to, liability for any negligent act or omissions) to any person in
      respect of any claims or losses of any nature, arising directly or indirectly from: (i) anything done or the consequences of anything
      done or omitted to be done wholly or partly in reliance upon the whole or any part of the Service; and (ii) the use of any Information
      provided on the Website and/or as part of the Service or unauthorised access to this Website or Information or otherwise. The Exchange
      will not be responsible for any breach of these Terms caused by circumstances beyond its reasonable control. The Exchange will not be
      responsible for any delays in the delivery or failure to receive any SMS or email sent as part of the Service.
    </div>
    <div>
      <h3>3. ADVICE</h3>
    </div>
    <div>
      Information provided by the Service is not offered as advice on any particular matter and must not be treated as a substitute for
      specific advice. In particular Information does not constitute professional, financial or investment advice and must not be used as a
      basis for making investment decisions and is in no way intended, directly or indirectly, as an attempt to market or sell any type of
      financial instrument. Advice from a suitably qualified professional should always be sought in relation to any particular matter or
      circumstances.
    </div>
    <div>
      <h3>4. INVESTMENT</h3>
    </div>
    <div>
      The Exchange does not conduct investment business in the United Kingdom with private customers and accordingly services and products
      mentioned or referred to in any advertisements on this Website are not available to such persons via the Exchange. Please note that
      the provision of investment services may be restricted in certain jurisdictions. You are required to acquaint yourself with any local
      laws and restrictions on the usage of this Service and the availability of any services described therein. This Service is not
      intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution would be contrary
      to local law or regulation. The Information does not constitute an invitation to invest in shares of the Exchange, or constitute or
      form a part of any offer for the sale or subscription of, or any invitation to offer to buy or subscribe for, any securities or other
      financial instruments, nor should it or any part of it form the basis of, or be relied upon in any connection with any contract or
      commitment whatsoever.
    </div>
    <div>
      <h3>5. THIRD PARTY ADVERTISING</h3>
    </div>
    <div>
      We allow third parties to place advertisements on our Website. Third party advertisements are clearly labelled as such. The Exchange
      does not endorse and is not responsible for the content of any third party advertisement on this Website or otherwise.
    </div>

    <div>
      <h3>6. LINKS TO THIS AND OTHER WEBSITES</h3>
    </div>
    <div>You may not frame, link or deep-link this Website to any other website without our prior written consent.</div>
  </div>
</div>
