<div class="section-grid-container">
  <div class="form">
    <lseg-notification-alert
      *ngIf="deleteUserProfileRequestSuccess$ | async"
      [alertMessage]="'Your account has been deleted'"
      [showAlert]="true"
      [currentAlertState]="0"
      [alertWithNoOpacity]="false"
      [animationDuration]="0"
    ></lseg-notification-alert>
    <lseg-notification-alert
      *ngIf="isAuthenticated$ | async"
      [alertMessage]="'You need to sign out to be able to delete your account'"
      [showAlert]="true"
      [currentAlertState]="0"
      [alertWithNoOpacity]="false"
      [animationDuration]="0"
    ></lseg-notification-alert>
    <div class="section-grid-container spinner" *ngIf="deletingUserProfile$ | async">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
