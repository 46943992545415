import { createAction, props } from '@ngrx/store';
import { IncidentFilter } from 'src/app/interfaces/reducer.interface';
import { ArchivedIncidentResponse } from 'src/app/interfaces/response.interface';
import { MarketResponse } from 'src/app/modules/admin/interfaces/market.interface';
import { IncidentResponse, StatusEntity } from 'src/app/modules/home/interfaces/status.interface';
import { UpdatedUserEntity, UserEntity } from 'src/app/modules/user/interfaces/user.interface';
import { WamEntity } from 'src/app/modules/wam/interfaces/wam.interface';
export const prefix = '[App]';

export const validateAuth = createAction(`${prefix} validateAuth`);

export const userAuthenticated = createAction(`${prefix} userAuthenticated`, props<{ roles: string[]; loggedInUserId: string }>());

export const userUnauthenticated = createAction(`${prefix} userUnauthenticated`);

export const loadUserProfile = createAction(`${prefix} Load User Profile`);

export const loadUserProfileSuccess = createAction(`${prefix} Load User Profile Success`, props<{ profile: UserEntity }>());

export const loadUserProfileFailure = createAction(`${prefix} Load User Profile Failure`, props<{ error: any }>());

export const updateUserProfile = createAction(`${prefix} Update User Profile`, props<{ profile: UpdatedUserEntity }>());

export const updateUserProfileSuccess = createAction(`${prefix} Update User Profile Success`, props<{ profile: UserEntity }>());

export const updateUserProfileFailure = createAction(`${prefix} Update User Profile Failure`, props<{ error: any }>());

export const sendCancelUserRequest = createAction(`${prefix} Send Cancel User Request`, props<{ email: string }>());
export const sendCancelUserRequestSuccess = createAction(`${prefix} Send Cancel User Request Success`);
export const sendCancelUserRequestFailure = createAction(`${prefix} Send Cancel User Request Failure`, props<{ error: any }>());

export const deleteUserAccount = createAction(`${prefix} Delete User Account`, props<{ email: string; token: string }>());
export const deleteUserAccountSuccess = createAction(`${prefix} Delete User Account Success`);
export const deleteUserAccountFailure = createAction(`${prefix} Delete User Account Failure`, props<{ error: any }>());

export const logoutUser = createAction(`${prefix} logout User`);

export const loginWithOkta = createAction(`${prefix} Login Okta`);

export const loginSuccessWithOkta = createAction(`${prefix} Login Okta Success`, props<{ token: string; loggedInUserId: string }>());

export const loginHandleRdirectFromOkta = createAction(`${prefix} Login handle redirect from Okta`, props<{ token: string }>());

export const loginFailureWithOkta = createAction(`${prefix} Login Okta Failure`, props<{ error: any }>());

export const logoutWithOkta = createAction(`${prefix} Logout Okta`);

export const wamActivities = createAction(`${prefix} Wam Activities`);

export const wamActivitiesSuccess = createAction(`${prefix} Wam Activities Success`, props<{ wams: WamEntity[] }>());

export const wamActivitiesFailure = createAction(`${prefix} Wam Activities Failure`, props<{ error: string }>());

export const selectWam = createAction(`${prefix} select Wam`, props<{ wamId: string }>());

export const registerUser = createAction(`${prefix} Register User`, props<{ user: UserEntity }>());

export const registerUserSuccess = createAction(`${prefix} Register User Success`);

export const registerUserFailure = createAction(`${prefix} Register User Failure`, props<{ error: string }>());

export const systemStatuses = createAction(`${prefix} System Statuses`);

export const systemStatusesSuccess = createAction(`${prefix} System Statuses Success`, props<{ statuses: StatusEntity[] }>());

export const systemStatusesFailure = createAction(`${prefix} System Statuses Failure`, props<{ error: string }>());

export const systemStatusDetails = createAction(`${prefix} System Status Details`, props<{ id: string; market: string }>());

export const systemStatusDetailsSuccess = createAction(`${prefix} System Status Details Success`, props<{ details: IncidentResponse }>());

export const systemStatusDetailsFailure = createAction(`${prefix} System Status Details Failure`, props<{ error: string }>());

export const initArchivedIncidents = createAction(`${prefix} Init Archived Incidents`);

export const archivedIncidents = createAction(`${prefix} Archived Incidents`, props<{ page?: number; pageSize?: number }>());

export const archivedIncidentsSuccess = createAction(
  `${prefix} Archived Incidents Success`,
  props<{ response: ArchivedIncidentResponse }>()
);

export const archivedIncidentsFailure = createAction(`${prefix} Archived Incidents Failure`, props<{ error: string }>());

export const archivedIncidentsFilter = createAction(`${prefix} Archived Incidents Filter`, props<{ filter: IncidentFilter }>());

export const allMarkets = createAction(`${prefix} All Markets`);

export const allMarketsSuccess = createAction(`${prefix} All Markets Success`, props<{ response: MarketResponse[] }>());

export const allMarketsFailure = createAction(`${prefix} All Markets Failure`, props<{ error: string }>());
