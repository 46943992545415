import { InjectionToken, isDevMode } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';
import * as fromAdmin from './admin.reducer';
import * as fromApp from './app.reducer';

export interface RootState {
  [fromApp.appFeatureKey]: fromApp.State;
  [fromAdmin.adminFeatureKey]: fromAdmin.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<RootState, Action>>('Root reducers token', {
  factory: () => ({
    [fromApp.appFeatureKey]: fromApp.reducer,
    [fromAdmin.adminFeatureKey]: fromAdmin.reducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducersfgwe
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: Array<MetaReducer<RootState>> = isDevMode() ? [logger] : [];

export const selectAppState = createFeatureSelector<fromApp.State>(fromApp.appFeatureKey);

export const selectAdminState = createFeatureSelector<fromAdmin.State>(fromAdmin.adminFeatureKey);
