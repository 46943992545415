import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArchivedIncidentResponse, ResponseEntity } from 'src/app/interfaces/response.interface';
import { ArchivedIncidentRequest } from 'src/app/modules/archived-incidents/interfaces/archived-incidents.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArchivedIncidentsService {
  constructor(private http: HttpClient) {}

  getArchivedIncidents(request: ArchivedIncidentRequest) {
    return this.http.post<ResponseEntity<ArchivedIncidentResponse>>(`${environment.api.dynamo}/incident/archived`, request);
  }
}
