import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterLink } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { systemStatuses } from 'src/app/store/actions/app.actions';
import { State } from 'src/app/store/reducers/app.reducer';
import { selectSystemStatuses } from 'src/app/store/selectors/app.selectors';
import { StatusEntity } from '../../interfaces/status.interface';

@UntilDestroy()
@Component({
  selector: 'lsp-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, AsyncPipe, NgClass, RouterLink, MatProgressSpinnerModule],
})
export class StatusComponent implements OnInit {
  isContactUs = true;
  statuses$: Observable<StatusEntity[]> = this._store.select(selectSystemStatuses);

  constructor(
    private readonly _store: Store<State>,
    private readonly _router: Router,
    private readonly _authService: MsalService
  ) {
    this.isContactUs = this._router.url === '/contact-us';
  }

  ngOnInit(): void {
    this.initialLoad();
    this.checkAuthRedirect();
  }

  initialLoad(): void {
    this._store.dispatch(systemStatuses());
  }

  checkAuthRedirect(): void {
    this._authService.handleRedirectObservable().pipe(untilDestroyed(this)).subscribe();
  }
}
