import { createAction, props } from '@ngrx/store';
import {
  IncidentCreateUpdateRequest,
  IncidentFilter,
  IncidentRequest,
  MarketRequest,
  SubIncidentCreateRequest,
  SubIncidentDeleteRequest,
  TemplateRequest,
} from 'src/app/interfaces/reducer.interface';
import { IncidentsResponse, TemplatesResponse } from 'src/app/interfaces/response.interface';
import { IncidentEntity } from 'src/app/modules/admin/interfaces/incident.interface';
import { MarketResponse } from 'src/app/modules/admin/interfaces/market.interface';
import { MarketTemplate, TemplateEntity } from 'src/app/modules/admin/interfaces/template.interface';
import { WamRequest } from 'src/app/modules/admin/interfaces/wam.interface';
import { WamEntity } from 'src/app/modules/wam/interfaces/wam.interface';
export const prefix = '[Admin]';

export const adminMarket = createAction(`${prefix} Admin Market`, props<{ id: string }>());

export const adminMarketSuccess = createAction(`${prefix} Admin Market Success`, props<{ response: MarketResponse }>());

export const adminMarketFailure = createAction(`${prefix} Admin Market Failure`, props<{ error: string }>());

export const removeMarket = createAction(`${prefix} Remove Market`, props<{ id: string }>());

export const removeMarketSuccess = createAction(`${prefix} Remove Market Success`, props<{ message: string }>());

export const removeMarketFailure = createAction(`${prefix} Remove Market Failure`, props<{ error: string }>());

export const addMarketRedirect = createAction(`${prefix} Add Market Redirect`);

export const cancelMarketRedirect = createAction(`${prefix} Cancel Market Redirect`);

export const updateMarketRedirect = createAction(`${prefix} Update Market Redirect`, props<{ id: string }>());

export const updateMarket = createAction(`${prefix} Update Market`, props<{ request: MarketRequest }>());

export const updateMarketSuccess = createAction(`${prefix} Update Market Success`, props<{ message: string }>());

export const updateMarketFailure = createAction(`${prefix} Update Market Failure`, props<{ error: string }>());

export const saveMarket = createAction(`${prefix} Save Market`, props<{ request: MarketRequest }>());

export const saveMarketSuccess = createAction(`${prefix} Save Market Success`, props<{ message: string }>());

export const saveMarketFailure = createAction(`${prefix} Save Market Failure`, props<{ error: string }>());

export const initAdminIncidents = createAction(`${prefix} Init Admin Incidents`);

export const adminIncidents = createAction(`${prefix} Admin Incidents`, props<{ page?: number; pageSize?: number }>());

export const adminIncidentsSuccess = createAction(`${prefix} Admin Incidents Success`, props<{ response: IncidentsResponse }>());

export const adminIncidentsFailure = createAction(`${prefix} Admin Incidents Failure`, props<{ error: string }>());

export const adminIncidentsFilter = createAction(`${prefix} Admin Incidents Filter`, props<{ filter: IncidentFilter }>());

export const initAdminIncident = createAction(`${prefix} Init Admin Incident`, props<{ request: IncidentRequest }>());

export const adminIncident = createAction(`${prefix} Admin Incident`, props<{ request: IncidentRequest }>());

export const adminIncidentSuccess = createAction(`${prefix} Admin Incident Success`, props<{ response: IncidentEntity }>());

export const adminIncidentFailure = createAction(`${prefix} Admin Incident Failure`, props<{ error: string }>());

export const adminMarkets = createAction(`${prefix} Admin Markets`);

export const adminMarketsSuccess = createAction(`${prefix} Admin Markets Success`, props<{ response: MarketResponse[] }>());

export const adminMarketsFailure = createAction(`${prefix} Admin Markets Failure`, props<{ error: string }>());

export const adminIncidentsTemplates = createAction(`${prefix} Admin Incidents Templates`, props<{ market: string }>());

export const adminIncidentsTemplatesSuccess = createAction(
  `${prefix} Admin Incidents Templates Success`,
  props<{ response: MarketTemplate[] }>()
);

export const adminIncidentsTemplatesFailure = createAction(`${prefix} Admin Incidents Templates Failure`, props<{ error: string }>());

export const removeIncidents = createAction(`${prefix} Remove Incidents`, props<{ request: IncidentRequest }>());

export const removeIncidentsSuccess = createAction(`${prefix} Remove Incidents Success`, props<{ response: IncidentEntity }>());

export const removeIncidentsFailure = createAction(`${prefix} Remove Incidents Failure`, props<{ error: string }>());

export const removeSubIncidents = createAction(`${prefix} Remove Sub Incidents`, props<{ request: SubIncidentDeleteRequest[] }>());

export const removeSubIncidentsSuccess = createAction(`${prefix} Remove Sub Incidents Success`, props<{ message: string }>());

export const removeSubIncidentsFailure = createAction(`${prefix} Remove Sub Incidents Failure`, props<{ error: string }>());

export const addIncidentsRedirect = createAction(`${prefix} Add Incidents Redirect`);

export const cancelIncidentsRedirect = createAction(`${prefix} Cancel Incidents Redirect`);

export const updateIncidentsRedirect = createAction(`${prefix} Update Incidents Redirect`, props<{ request: IncidentRequest }>());

export const updateIncidents = createAction(
  `${prefix} Update Incidents`,
  props<{
    request: IncidentCreateUpdateRequest;
    addSubIncidents: SubIncidentCreateRequest[];
    deleteSubIncidents: SubIncidentDeleteRequest[];
  }>()
);

export const updateIncidentsSuccess = createAction(`${prefix} Update Incidents Success`);

export const updateIncidentsFailure = createAction(`${prefix} Update Incidents Failure`, props<{ error: string }>());

export const saveIncidents = createAction(`${prefix} Save Incidents`, props<{ request: IncidentCreateUpdateRequest }>());

export const saveIncidentsSuccess = createAction(`${prefix} Save Incidents Success`);

export const saveIncidentsFailure = createAction(`${prefix} Save Incidents Failure`, props<{ error: string }>());

export const saveSubIncidents = createAction(`${prefix} Save Sub Incidents`, props<{ request: SubIncidentCreateRequest[] }>());

export const saveSubIncidentsSuccess = createAction(`${prefix} Save Sub Incidents Success`);

export const saveSubIncidentsFailure = createAction(`${prefix} Save Sub Incidents Failure`, props<{ error: string }>());

export const archiveIncidents = createAction(`${prefix} Archive Incidents`, props<{ request: IncidentRequest }>());

export const archiveIncidentsSuccess = createAction(`${prefix} Archive Incidents Success`, props<{ response: IncidentEntity }>());

export const archiveIncidentsFailure = createAction(`${prefix} Archive Incidents Failure`, props<{ error: string }>());

export const adminTemplates = createAction(`${prefix} Admin Templates`, props<{ page?: number; pageSize?: number }>());

export const adminTemplatesSuccess = createAction(`${prefix} Admin Templates Success`, props<{ response: TemplatesResponse }>());

export const adminTemplatesFailure = createAction(`${prefix} Admin Templates Failure`, props<{ error: string }>());

export const initAdminTemplate = createAction(`${prefix} Init Admin Template`, props<{ id: string }>());

export const adminTemplate = createAction(`${prefix} Admin Template`, props<{ id: string }>());

export const adminTemplateSuccess = createAction(`${prefix} Admin Template Success`, props<{ response: TemplateEntity }>());

export const adminTemplateFailure = createAction(`${prefix} Admin Template Failure`, props<{ error: string }>());

export const removeTemplate = createAction(`${prefix} Remove Template`, props<{ id: string }>());

export const removeTemplateSuccess = createAction(`${prefix} Remove Template Success`, props<{ message: string }>());

export const removeTemplateFailure = createAction(`${prefix} Remove Template Failure`, props<{ error: string }>());

export const addTemplateRedirect = createAction(`${prefix} Add Template Redirect`);

export const cancelTemplateRedirect = createAction(`${prefix} Cancel Template Redirect`);

export const updateTemplateRedirect = createAction(`${prefix} Update Template Redirect`, props<{ id: string }>());

export const updateTemplate = createAction(`${prefix} Update Template`, props<{ request: TemplateRequest }>());

export const updateTemplateSuccess = createAction(`${prefix} Update Template Success`, props<{ message: string }>());

export const updateTemplateFailure = createAction(`${prefix} Update Template Failure`, props<{ error: string }>());

export const saveTemplate = createAction(`${prefix} Save Template`, props<{ request: TemplateRequest }>());

export const saveTemplateSuccess = createAction(`${prefix} Save Template Success`, props<{ message: string }>());

export const saveTemplateFailure = createAction(`${prefix} Save Template Failure`, props<{ error: string }>());

export const adminWams = createAction(`${prefix} Admin Wams`, props<{ page?: number; pageSize?: number }>());

export const adminWamsSuccess = createAction(`${prefix} Admin Wams Success`, props<{ response: WamEntity[] }>());

export const adminWamsFailure = createAction(`${prefix} Admin Wams Failure`, props<{ error: string }>());

export const initAdminWam = createAction(`${prefix} Init Admin Wam`, props<{ id: string }>());

export const adminWam = createAction(`${prefix} Admin Wam`, props<{ id: string }>());

export const adminWamSuccess = createAction(`${prefix} Admin Wam Success`, props<{ response: WamEntity }>());

export const adminWamFailure = createAction(`${prefix} Admin Wam Failure`, props<{ error: string }>());

export const removeWam = createAction(`${prefix} Remove Wam`, props<{ id: string }>());

export const removeWamSuccess = createAction(`${prefix} Remove Wam Success`, props<{ message: string }>());

export const removeWamFailure = createAction(`${prefix} Remove Wam Failure`, props<{ error: string }>());

export const addWamRedirect = createAction(`${prefix} Add Wam Redirect`);

export const cancelWamRedirect = createAction(`${prefix} Cancel Wam Redirect`);

export const updateWamRedirect = createAction(`${prefix} Update Wam Redirect`, props<{ id: string }>());

export const updateWam = createAction(`${prefix} Update Wam`, props<{ request: WamRequest }>());

export const updateWamSuccess = createAction(`${prefix} Update Wam Success`, props<{ message: string }>());

export const updateWamFailure = createAction(`${prefix} Update Wam Failure`, props<{ error: string }>());

export const saveWam = createAction(`${prefix} Save Wam`, props<{ request: WamRequest }>());

export const saveWamSuccess = createAction(`${prefix} Save Wam Success`, props<{ message: string }>());

export const saveWamFailure = createAction(`${prefix} Save Wam Failure`, props<{ error: string }>());
