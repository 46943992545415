<div *ngIf="!isContactUs" class="section-grid-container">
  <div class="single-content-wrapper">
    <h1>Current system status</h1>
  </div>
</div>
<div class="section-grid-container statuses" *ngIf="(statuses$ | async).length > 0; else spinner">
  <div *ngFor="let item of statuses$ | async; index as i" class="status">
    <i class="fa-solid fa-2xl" [ngClass]="{ 'fa-circle-xmark': !item.status, 'fa-circle-check': item.status }"></i>
    <h4>{{ item.marketTitle }}</h4>
    <ng-container *ngIf="!isContactUs">
      <div *ngIf="item?.incidents.length > 0; else status_ok">
        <div *ngFor="let incident of item.incidents">
          <a class="status-bad" [routerLink]="['/incident/' + incident.incidentId + '/' + item.marketTitle]">
            {{ incident.incidentTitle }}
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #status_ok>
  <div class="status-ok">All systems running normally</div>
</ng-template>
<ng-template #spinner>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
