import { DropdownOption, NavTab } from '@issuerservices/design-system';
import { createSelector } from '@ngrx/store';
import { selectAppState } from '../reducers';

export const selectIsLoggedIn = createSelector(selectAppState, (state) => state.isLoggedIn);
export const selectIsLoggedInOkta = createSelector(selectAppState, (state) => state.isLoginOkta);
export const selectToken = createSelector(selectAppState, (state) => state.token);
export const selectAuthInfo = createSelector(selectIsLoggedIn, selectIsLoggedInOkta, (isLoggedIn, isLoginOkta) => ({
  isLoggedIn,
  isLoginOkta,
}));
export const selectAuthData = createSelector(selectAuthInfo, selectToken, (info, token) => ({
  ...info,
  token,
}));
export const selectloggedInUserId = createSelector(selectAppState, (state) => state.loggedInUserId?.toLowerCase());
export const selectUserProfile = createSelector(selectAppState, (state) => state.userProfile);

export const selectUserProfileLoading = createSelector(selectAppState, (state) => state.userProfileLoading);
export const updatingUserProfile = createSelector(selectAppState, (state) => state.updatingUserProfile);
export const selectDeletingUserProfile = createSelector(selectAppState, (state) => state.deleteUserProfileRequestLoading);
export const selectUserCancelProfileRequested = createSelector(selectAppState, (state) => state.userCancelProfileRequested);
export const selectDeleteUserProfileRequestSuccess = createSelector(selectAppState, (state) => state.deleteUserProfileRequestSuccess);
export const selectWamsLoading = createSelector(selectAppState, (state) => state.wamsLoading);
export const selectWams = createSelector(selectAppState, (state) => state.wams);

export const selectSelectedWamId = createSelector(selectAppState, (state) => state.selectedWamId);

export const selectSelectedWam = createSelector(selectWams, selectSelectedWamId, (wams, id) => wams && wams.find((el) => el.wamId === id));

export const selectWamTabs = createSelector(selectWams, (wams) =>
  wams ? wams.map((el) => ({ name: el.market, path: `/wam/${el.wamId}` }) as NavTab) : []
);

export const selectUserProcessing = createSelector(selectAppState, (state) => state.userProcessing);

export const selectSystemStatuses = createSelector(selectAppState, (state) => state.systemStatuses);

export const selectSelectedIncident = createSelector(selectAppState, (state) => state.selectedIncident);

export const selectArchivedIncidents = createSelector(selectAppState, (state) => state.archivedIncidents);

export const selectArchivedPage = createSelector(selectAppState, (state) => state.archivedIncidentsPage[1] - 1);

export const selectArchivedMarkets = createSelector(selectAppState, (state) => [
  { key: 'all', name: 'All Markets' },
  ...state.archivedMarkets,
]);
export const selectArchivedTotal = createSelector(selectAppState, (state) => state.archivedIncidentsTotal);
export const selectArchivedPageSize = createSelector(selectAppState, (state) => state.archivedIncidentsPageSize);
export const selectArchivedIncidentRequest = createSelector(selectAppState, (state) => ({
  lastEvaluatedKey: state.archivedIncidentsKey,
  lastEvaluatedIndex: state.archivedIncidentsKeyIndex,
  role: state.isAdmin ? 'ADMIN' : 'USER',
  pageSize: state.archivedIncidentsPageSize,
  currentPageNumber: state.archivedIncidentsPage,
  isFilterChanged: state.archivedIncidentsFilterChanged,
  filters: {
    filterDate: state.archivedFilter?.date ?? null,
    incidentTitle: state.archivedFilter?.title ?? null,
    marketTitle: state.archivedFilter?.market ?? null,
  },
}));
export const selectIsAdmin = createSelector(selectAppState, (state) => state.isAdmin);
export const selectUserMarkets = createSelector(selectAppState, (state) =>
  state.archivedMarkets.map(
    (el) =>
      ({
        viewValue: el.name,
        value: el.name,
      }) as DropdownOption
  )
);
