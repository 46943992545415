import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { State } from '../store/reducers/app.reducer';
import { selectAuthData } from '../store/selectors/app.selectors';

@UntilDestroy()
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  oktaData = this._store.select(selectAuthData);
  constructor(private readonly _store: Store<State>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders;
    if (req.headers.has('Authorization')) {
      headers = req.headers;
    } else {
      this.oktaData.pipe(take(1), untilDestroyed(this)).subscribe((val) => {
        if (
          val.isLoggedIn &&
          val.isLoginOkta &&
          (req.url.includes(environment.oktaInfo.oktaConfig.profileUrl) || req.url.includes(environment.oktaInfo.oktaConfig.cancelUrl))
        ) {
          headers = req.headers.set('Authorization', 'Bearer ' + val.token);
        } else {
          if (req.url.includes(environment.oktaInfo.oktaConfig.deleteUrl)) {
            headers = req.headers;
          } else {
            headers = req.headers.set('Authorization', 'Bearer public');
          }
        }
      });
    }
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
