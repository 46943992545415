<div *ngIf="data; else spinner">
  <lseg-aurora-table
    [stickyHeaderOffset]="false"
    [dataSource]="tableData"
    [displayedColumns]="isAdmin ? adminTableColumns : tableColumns"
    noDataText="No incidents"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.incidentId }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status" *ngIf="isAdmin">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.incidentStatus }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="published" *ngIf="isAdmin">
      <mat-header-cell *matHeaderCellDef>Published</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.publishedStatus ? 'Yes' : 'No' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="['/incident/' + element.incidentId + '/' + element.marketTitle]">{{ element.incidentTitle }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="market">
      <mat-header-cell *matHeaderCellDef>Market</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.marketTitle }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef>Created on</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.createdOn | date: 'EEE, dd.MM.yyyy - HH:mm' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="subincidents">
      <mat-header-cell *matHeaderCellDef>Subincidents</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.subIncidentEntities?.length ?? 0 }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="operations" *ngIf="isAdmin">
      <mat-header-cell *matHeaderCellDef>Operations</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.incidentStatus !== 'ARCHIVED'">
          <lseg-aurora-button
            *ngIf="element.incidentStatus !== 'CLOSED'"
            accent="tertiary"
            icon="pen"
            size="small"
            (buttonClick)="editItem(element.incidentId, element.marketTitle)"
          >
            Edit
          </lseg-aurora-button>
          <lseg-aurora-button
            *ngIf="!element.sentNotification && element.incidentStatus !== 'CLOSED'"
            accent="tertiary"
            icon="trash"
            size="small"
            (buttonClick)="removeItem(element.incidentId, element.marketTitle)"
          >
            Delete
          </lseg-aurora-button>
          <lseg-aurora-button
            *ngIf="element.sentNotification"
            accent="tertiary"
            icon="box-archive"
            size="small"
            (buttonClick)="archiveItem(element.incidentId, element.marketTitle)"
          >
            Archive
          </lseg-aurora-button>
        </ng-container>
      </mat-cell>
    </ng-container>
  </lseg-aurora-table>
</div>
<ng-template #spinner>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
