import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from 'src/environments/environment';
import { IncidentResponse, StatusEntity } from '../interfaces/status.interface';

@Injectable({
  providedIn: 'root',
})
export class StatusApiService {
  constructor(private http: HttpClient) {}

  getAllStatuses(): Observable<ResponseEntity<StatusEntity[]>> {
    return this.http.get<ResponseEntity<StatusEntity[]>>(`${environment.api.dynamo}/home`);
  }

  getIncident(id: string, market: string): Observable<ResponseEntity<IncidentResponse>> {
    return this.http.get<ResponseEntity<IncidentResponse>>(`${environment.api.dynamo}/incident/get/${id}/${market}`);
  }
}
