import { IDropdownSelectOption } from '@issuerservices/design-system';
import { createReducer, on } from '@ngrx/store';
import { IncidentFilter } from 'src/app/interfaces/reducer.interface';
import { Incident } from 'src/app/interfaces/response.interface';
import { IncidentResponse, StatusEntity } from 'src/app/modules/home/interfaces/status.interface';
import { UserEntity } from 'src/app/modules/user/interfaces/user.interface';
import { WamEntity } from 'src/app/modules/wam/interfaces/wam.interface';
import {
  allMarketsSuccess,
  archivedIncidentsFilter,
  archivedIncidentsSuccess,
  deleteUserAccount,
  deleteUserAccountFailure,
  deleteUserAccountSuccess,
  initArchivedIncidents,
  loadUserProfile,
  loadUserProfileFailure,
  loadUserProfileSuccess,
  loginSuccessWithOkta,
  logoutWithOkta,
  registerUser,
  registerUserFailure,
  registerUserSuccess,
  selectWam,
  sendCancelUserRequest,
  sendCancelUserRequestFailure,
  sendCancelUserRequestSuccess,
  systemStatusDetails,
  systemStatusDetailsSuccess,
  systemStatusesSuccess,
  updateUserProfile,
  updateUserProfileFailure,
  updateUserProfileSuccess,
  userAuthenticated,
  userUnauthenticated,
  wamActivities,
  wamActivitiesSuccess,
} from '../actions/app.actions';

export const appFeatureKey = 'app';
export const defaultPageSize = 25;
export const adminRole = 'Admin';

export interface State {
  isLoggedIn: boolean;
  isAdmin: boolean;
  wamsLoading: boolean;
  wams: WamEntity[];
  selectedWamId: string;
  userProcessing: boolean;
  systemStatuses: StatusEntity[];
  selectedIncident: IncidentResponse;
  archivedIncidents: Incident[];
  archivedMarkets: IDropdownSelectOption[];
  archivedFilter: IncidentFilter;
  token: string | null;
  isLoginOkta: boolean;
  archivedIncidentsPageSize: number;
  archivedIncidentsPage: [number, number];
  archivedIncidentsTotal: number;
  archivedIncidentsKey: [string, string];
  archivedIncidentsKeyIndex: [number, number];
  archivedIncidentsFilterChanged: boolean;
  userProfile: UserEntity;
  userProfileLoading: boolean;
  loggedInUserId: string;
  updatingUserProfile: boolean;
  deleteUserProfileRequestLoading: boolean;
  userCancelProfileRequested: boolean;
  deleteUserProfileRequestSuccess: boolean;
}

export const initialState: State = {
  isLoggedIn: false,
  isAdmin: false,
  wamsLoading: false,
  wams: null,
  selectedWamId: null,
  userProcessing: false,
  systemStatuses: [],
  selectedIncident: null,
  token: null,
  isLoginOkta: false,
  loggedInUserId: null,
  archivedIncidents: null,
  archivedMarkets: [],
  archivedFilter: null,
  archivedIncidentsPageSize: defaultPageSize,
  archivedIncidentsPage: [1, 1],
  archivedIncidentsTotal: -1,
  archivedIncidentsKey: [null, null],
  archivedIncidentsKeyIndex: [0, 0],
  archivedIncidentsFilterChanged: false,
  userProfile: null,
  userProfileLoading: false,
  updatingUserProfile: false,
  deleteUserProfileRequestLoading: false,
  userCancelProfileRequested: false,
  deleteUserProfileRequestSuccess: false,
};

export const reducer = createReducer(
  initialState,
  on(userUnauthenticated, (): State => {
    return { ...initialState, isLoggedIn: false };
  }),
  on(userAuthenticated, (state, action): State => {
    return { ...state, isLoggedIn: true, isAdmin: action.roles.includes(adminRole), loggedInUserId: action.loggedInUserId };
  }),
  on(wamActivities, (state): State => {
    return { ...state, wamsLoading: true };
  }),
  on(wamActivitiesSuccess, (state, action): State => {
    return { ...state, wams: action.wams ?? [], wamsLoading: false };
  }),
  on(selectWam, (state, action): State => {
    return { ...state, selectedWamId: action.wamId };
  }),
  on(registerUser, (state): State => {
    return { ...state, userProcessing: true };
  }),
  on(registerUserSuccess, (state): State => {
    return { ...state, userProcessing: false };
  }),
  on(registerUserFailure, (state): State => {
    return { ...state, userProcessing: false };
  }),
  on(systemStatusesSuccess, (state, action): State => {
    return { ...state, systemStatuses: action.statuses };
  }),
  on(systemStatusDetails, (state): State => {
    return { ...state, selectedIncident: null };
  }),
  on(systemStatusDetailsSuccess, (state, action): State => {
    return { ...state, selectedIncident: action.details };
  }),
  on(loginSuccessWithOkta, (state, { token, loggedInUserId }) => ({
    ...state,
    token,
    isLoggedIn: true,
    isLoginOkta: true,
    loggedInUserId: loggedInUserId,
  })),
  on(logoutWithOkta, (state) => ({ ...state, token: null, isLoggedIn: false, isLoginOkta: false })),
  on(systemStatusDetailsSuccess, (state, action): State => {
    return { ...state, selectedIncident: action.details };
  }),
  on(initArchivedIncidents, (state) => ({
    ...state,
    archivedIncidents: null,
    archivedMarkets: [],
    archivedFilter: null,
    archivedIncidentsPageSize: defaultPageSize,
    archivedIncidentsPage: [1, 1] as [number, number],
    archivedIncidentsTotal: -1,
    archivedIncidentsKey: [null, null] as [string, string],
    archivedIncidentsKeyIndex: [0, 0] as [number, number],
    archivedIncidentsFilterChanged: false,
  })),
  on(archivedIncidentsSuccess, (state, action): State => {
    const wasReload =
      state.archivedIncidentsKey[1] !== null && state.archivedIncidentsKey[1] === action.response.pagination.nextLastEvaluatedKey;
    return {
      ...state,
      archivedIncidents: action.response.incidents,
      archivedIncidentsTotal: action.response.pagination.totalRecords,
      archivedIncidentsPageSize: action.response.pagination.pageSize,
      archivedIncidentsPage: wasReload
        ? state.archivedIncidentsPage
        : [state.archivedIncidentsPage[1], action.response.pagination.currentPage],
      archivedIncidentsKey: wasReload
        ? state.archivedIncidentsKey
        : [state.archivedIncidentsKey[1], action.response.pagination.nextLastEvaluatedKey],
      archivedIncidentsKeyIndex: wasReload
        ? state.archivedIncidentsKeyIndex
        : [state.archivedIncidentsKeyIndex[1], action.response.pagination.nextLastEvaluatedIndex],
      archivedIncidentsFilterChanged: false,
    };
  }),
  on(archivedIncidentsFilter, (state, action): State => {
    return { ...state, archivedFilter: action.filter, archivedIncidentsFilterChanged: true };
  }),
  on(allMarketsSuccess, (state, action): State => {
    return {
      ...state,
      archivedMarkets: [
        ...action.response.filter((p) => p.published === true).map((el) => ({ key: el.marketTitle, name: el.marketTitle })),
      ],
    };
  }),
  on(loadUserProfile, (state) => ({ ...state, userProfileLoading: true })),
  on(loadUserProfileSuccess, (state, { profile }) => ({ ...state, userProfile: profile, userProfileLoading: false })),
  on(loadUserProfileFailure, (state, { error }) => ({ ...state, userProfileLoading: false })),
  on(updateUserProfile, (state) => ({ ...state, updatingUserProfile: true })),
  on(updateUserProfileSuccess, (state, { profile }) => ({ ...state, updatingUserProfile: false })),
  on(updateUserProfileFailure, (state, { error }) => ({ ...state, updatingUserProfile: false })),
  on(sendCancelUserRequest, (state) => ({ ...state, updatingUserProfile: true, userCancelProfileRequested: false })),
  on(sendCancelUserRequestSuccess, (state) => ({ ...state, updatingUserProfile: false, userCancelProfileRequested: true })),
  on(sendCancelUserRequestFailure, (state, { error }) => ({ ...state, updatingUserProfile: false, userCancelProfileRequested: false })),
  on(deleteUserAccount, (state) => ({ ...state, deleteUserProfileRequestLoading: true, deleteUserProfileRequestSuccess: false })),
  on(deleteUserAccountSuccess, (state) => ({ ...state, deleteUserProfileRequestLoading: false, deleteUserProfileRequestSuccess: true })),
  on(deleteUserAccountFailure, (state, { error }) => ({
    ...state,
    deleteUserProfileRequestLoading: false,
    deleteUserProfileRequestSuccess: false,
  }))
);
