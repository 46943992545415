<div class="section-grid-container" *ngIf="incident$ | async as incident; else spinner">
  <div class="single-content-wrapper">
    <h2>{{ incident.incidentTitle }} for {{ incident.marketTitle }}</h2>
    <div *ngIf="incident.incidentDescription" [innerHtml]="incident.incidentDescription"></div>
    <div *ngIf="incident.subIncidentEntities.length > 0" class="sub-incidents">
      <div class="incident-container" *ngFor="let subIncident of incident.subIncidentEntities">
        <div class="incident-content">
          <h4>{{ subIncident.subIncidentTitle }}</h4>
          <div *ngIf="subIncident.subIncidentDescription" [innerHtml]="subIncident.subIncidentDescription"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
