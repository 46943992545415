import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseEntity } from 'src/app/interfaces/response.interface';
import { environment } from '../../../../environments/environment';
import { WamEntity } from '../interfaces/wam.interface';

@Injectable({
  providedIn: 'root',
})
export class WamApiService {
  constructor(private http: HttpClient) {}

  getAllWamActivities(): Observable<ResponseEntity<WamEntity[]>> {
    return this.http.get<ResponseEntity<WamEntity[]>>(`${environment.api.wam}/home/activities`);
  }
}
