import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lsp-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  standalone: true,
})
export class TermsConditionsComponent {}
