import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AuroraEmptyStateModule, IDropdownSelectOption, PaginatorModule } from '@issuerservices/design-system';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IncidentFilter } from 'src/app/interfaces/reducer.interface';
import { Incident } from 'src/app/interfaces/response.interface';
import { IncidentsFilterComponent } from 'src/app/shared/incidents-filter/incidents-filter.component';
import { archivedIncidents, archivedIncidentsFilter, initArchivedIncidents } from 'src/app/store/actions/app.actions';
import { State } from 'src/app/store/reducers/app.reducer';
import {
  selectArchivedIncidents,
  selectArchivedMarkets,
  selectArchivedPage,
  selectArchivedPageSize,
  selectArchivedTotal,
} from 'src/app/store/selectors/app.selectors';
import { IncidentsComponent } from '../../../shared/incidents/incidents.component';

@Component({
  selector: 'app-archived-incidents',
  standalone: true,
  imports: [IncidentsComponent, IncidentsFilterComponent, AsyncPipe, PaginatorModule, AuroraEmptyStateModule, NgIf],
  templateUrl: './archived-incidents.component.html',
  styleUrls: ['./archived-incidents.component.scss'],
})
export class ArchivedIncidentsComponent {
  isContactUs = true;
  incidents$: Observable<Incident[]> = this._store.select(selectArchivedIncidents);
  markets$: Observable<IDropdownSelectOption[]> = this._store.select(selectArchivedMarkets);
  page$: Observable<number> = this._store.select(selectArchivedPage);
  pageSize$: Observable<number> = this._store.select(selectArchivedPageSize);
  total$: Observable<number> = this._store.select(selectArchivedTotal);

  constructor(private readonly _store: Store<State>) {}

  ngOnInit(): void {
    this.initialLoad();
  }

  initialLoad(): void {
    this._store.dispatch(initArchivedIncidents());
  }

  applyFilter(filter: IncidentFilter) {
    this._store.dispatch(archivedIncidentsFilter({ filter }));
  }

  changePage(event: PageEvent): void {
    this._store.dispatch(
      archivedIncidents({
        page: event.pageIndex + 1,
        pageSize: event.pageSize,
      })
    );
  }
}
