import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuroraButtonModule, SpinnerLoaderModule } from '@issuerservices/design-system';
import { UntilDestroy } from '@ngneat/until-destroy';

type modalState = 'initial' | 'success' | 'error' | 'loading';

@UntilDestroy()
@Component({
  selector: 'lsp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, CommonModule, AuroraButtonModule, SpinnerLoaderModule],
  standalone: true,
})
export class ModalComponent implements OnInit {
  state: modalState = 'initial';
  working: boolean = false;
  modalData: unknown;
  timeLeft = 10;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      content: string;
      contentSize: number;
      showSpinner: boolean;
      confirm: string;
      header: string;
      icon: string;
      iconStyle: string;
    },
    private dialogRef: MatDialogRef<ModalComponent>,
    private ref: ChangeDetectorRef
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
    if (this.data.showSpinner) {
      this.changeState('loading');
    }
  }

  changeState(state: modalState): void {
    this.state = state;
    this.ref.detectChanges();
  }

  close(): void {
    this.state === 'success' ? this.dialogRef.close({ refresh: true }) : this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close({ confirm: true });
  }
}
