<div class="section-grid-container">
  <div class="single-content-wrapper">
    <lsp-incidents-filter [marketOptions]="markets$ | async" (filter)="applyFilter($event)"></lsp-incidents-filter>
    <div *ngIf="(total$ | async) !== 0; else empty">
      <lsp-incidents [data]="incidents$ | async"></lsp-incidents>
      <lseg-paginator
        [pageSizeOptions]="[10, 25, 50, 75, 100]"
        [length]="total$ | async"
        [pageSize]="pageSize$ | async"
        [pageIndex]="page$ | async"
        (page)="changePage($event)"
        [showFirstLastButtons]="true"
      ></lseg-paginator>
    </div>
  </div>
</div>
<ng-template #empty>
  <lseg-aurora-empty-state icon="triangle-exclamation" color="warn" header="No incidents" [wideGap]="true"> </lseg-aurora-empty-state>
</ng-template>
