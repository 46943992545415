import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LsegConfigService } from '@issuerservices/design-system';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ValidationError } from 'src/app/interfaces/response.interface';
import { AdminMarketApiService } from 'src/app/modules/admin/services/market.service';
import { ArchivedIncidentsService } from 'src/app/modules/archived-incidents/services/archived-incidents.service';
import { StatusApiService } from 'src/app/modules/home/services/status.service';
import { LocalStorageService } from 'src/app/modules/user/services/local-storage.service';
import { UserApiService } from 'src/app/modules/user/services/user.service';
import { WamApiService } from 'src/app/modules/wam/services/wam.service';
import { pageRequest } from 'src/app/utils/page-request';
import { environment } from 'src/environments/environment';
import {
  allMarkets,
  allMarketsFailure,
  allMarketsSuccess,
  archivedIncidents,
  archivedIncidentsFailure,
  archivedIncidentsFilter,
  archivedIncidentsSuccess,
  deleteUserAccount,
  deleteUserAccountFailure,
  deleteUserAccountSuccess,
  initArchivedIncidents,
  loadUserProfile,
  loadUserProfileFailure,
  loadUserProfileSuccess,
  loginFailureWithOkta,
  loginSuccessWithOkta,
  loginWithOkta,
  logoutUser,
  logoutWithOkta,
  registerUser,
  registerUserFailure,
  registerUserSuccess,
  sendCancelUserRequest,
  sendCancelUserRequestFailure,
  sendCancelUserRequestSuccess,
  systemStatusDetails,
  systemStatusDetailsFailure,
  systemStatusDetailsSuccess,
  systemStatuses,
  systemStatusesFailure,
  systemStatusesSuccess,
  updateUserProfile,
  updateUserProfileFailure,
  updateUserProfileSuccess,
  userAuthenticated,
  userUnauthenticated,
  validateAuth,
  wamActivities,
  wamActivitiesFailure,
  wamActivitiesSuccess,
} from '../actions/app.actions';
import { State } from '../reducers/app.reducer';
import {
  selectArchivedIncidentRequest,
  selectIsAdmin,
  selectIsLoggedInOkta,
  selectSelectedWamId,
  selectloggedInUserId,
} from '../selectors/app.selectors';

@Injectable()
export class AppEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>,
    private snackBar: MatSnackBar,
    private wamService: WamApiService,
    private statusService: StatusApiService,
    private userService: UserApiService,
    private archivedIncidentsService: ArchivedIncidentsService,
    private adminMarketService: AdminMarketApiService,
    private authService: MsalService,
    private config: LsegConfigService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private localStorageService: LocalStorageService
  ) {}

  // init
  ngrxOnInitEffects(): Action {
    this.authService.initialize();
    return validateAuth();
  }

  // check authentication status -  happens on init, on login, on logout
  initAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateAuth),
      switchMap(() => {
        const accounts = this.authService.instance.getAllAccounts();
        return [
          accounts.length > 0
            ? userAuthenticated({ roles: accounts[0].idTokenClaims?.roles ?? [], loggedInUserId: accounts[0].username })
            : userUnauthenticated(),
        ];
      })
    )
  );

  userAuthenticated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthenticated),
        withLatestFrom(this.store.select(selectIsAdmin)),
        tap(
          ([_, isAdmin]) => (this.config.config = { isLoggedIn: true, navigation: isAdmin ? environment.adminMenu : environment.userMenu })
        )
      ),
    { dispatch: false }
  );

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutUser),
        tap(() => this.authService.logoutRedirect()),
        tap(() => (this.config.config = { isLoggedIn: false }))
      ),
    { dispatch: false }
  );

  wamActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wamActivities),
      switchMap(() =>
        this.wamService.getAllWamActivities().pipe(
          map((response) =>
            (response.result && response.message === 'WAM records fetched successfully') ||
            response.message === 'No WAM Activities are scheduled'
              ? wamActivitiesSuccess({ wams: response.result })
              : wamActivitiesFailure({ error: response.message })
          ),
          catchError((error: Error) => of(wamActivitiesFailure({ error: error.message })))
        )
      )
    )
  );

  wamActivitiesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wamActivitiesSuccess),
        withLatestFrom(this.store.select(selectSelectedWamId)),
        tap(([action, id]) => {
          let path = '/wam';
          if (action.wams?.length > 0) {
            const ids = action.wams.map((el) => el.wamId);
            if (id && ids.includes(id)) {
              path += `/${id}`; // navigate to wam from URL
            } else {
              path += `/${ids[0]}`; // navigate to first wam
            }
          }
          this.router.navigate([path]);
        })
      ),
    { dispatch: false }
  );

  loadFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wamActivitiesFailure, systemStatusesFailure, systemStatusDetailsFailure, archivedIncidentsFailure, allMarketsFailure),
        tap((action) => console.error(action.error)),
        tap(() => this.router.navigate(['/contact-us'])),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerUser),
      switchMap((action) =>
        this.userService.createUser(action.user).pipe(
          map((response) =>
            response.message === 'User created successfully'
              ? registerUserSuccess()
              : registerUserFailure({
                  error: `${response.message}: ${response.errors ? response.errors.map((el) => `${el.field}: ${el.message}`).join(', ') : response.result}`,
                })
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.error) {
              return of(
                registerUserFailure({
                  error: `${error.error.message}: ${error.error.errors ? error.error.errors.map((el: ValidationError) => `${el.field}: ${el.message}`).join(', ') : error.error.result}`,
                })
              );
            }
            return of(registerUserFailure({ error: error.message }));
          })
        )
      )
    )
  );

  registerUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerUserSuccess),
        tap(() => this.router.navigate(['/user/login'], { queryParams: { register: 'success' } })),
        tap(() =>
          this.snackBar.open('User created successfully', 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'success-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  registerUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerUserFailure),
        tap((action) => console.error(action.error)),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  systemStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemStatuses),
      switchMap(() =>
        this.statusService.getAllStatuses().pipe(
          map((response) =>
            response.result && response.message === 'HomePage Response fetched successfully'
              ? systemStatusesSuccess({ statuses: response.result })
              : systemStatusesFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) => of(systemStatusesFailure({ error: error.message })))
        )
      )
    )
  );

  systemStatusDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemStatusDetails),
      switchMap((action) =>
        this.statusService.getIncident(action.id, action.market).pipe(
          map((response) =>
            response.result && response.message === 'Incident fetched successfully'
              ? systemStatusDetailsSuccess({ details: response.result })
              : systemStatusDetailsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.error) {
              return of(systemStatusDetailsFailure({ error: `${error.error.message}: ${error.error.result}` }));
            }
            return of(systemStatusDetailsFailure({ error: error.message }));
          })
        )
      )
    )
  );

  loginWithOkta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginWithOkta),
        tap(() => this.oktaAuth.signInWithRedirect({ originalUri: window.location.origin }))
      ),
    { dispatch: false }
  );

  loginSuccessWithOkta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessWithOkta),
        tap(({ token }) => this.localStorageService.setItem('token', token)),
        tap(() => (this.config.config = { isLoggedIn: true }))
        // tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  loginFailureWithOkta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailureWithOkta),
        tap(({ error }) => console.log('okta login error', error))
      ),
    { dispatch: false }
  );

  logoutWithOkta$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutWithOkta),
        tap(() => {
          this.localStorageService.removeItem('token');
          this.oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
        })
      ),
    { dispatch: false }
  );

  initArchivedIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initArchivedIncidents),

      switchMap(() => [archivedIncidents({}), allMarkets()])
    )
  );

  archivedIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archivedIncidents),
      withLatestFrom(this.store.select(selectArchivedIncidentRequest)),
      switchMap(([action, request]) =>
        this.archivedIncidentsService
          .getArchivedIncidents({
            ...pageRequest(
              request.lastEvaluatedKey,
              request.lastEvaluatedIndex,
              action?.pageSize ?? request.pageSize,
              request.currentPageNumber,
              action?.page ?? request.currentPageNumber[1],
              (action?.pageSize && action?.pageSize !== request.pageSize) || request.isFilterChanged
            ),
            role: request.role,
            filters: request.filters,
          })
          .pipe(
            map((response) =>
              response.result && response.message === 'Archived Incidents fetched successfully'
                ? archivedIncidentsSuccess({ response: response.result })
                : archivedIncidentsFailure({ error: response.message })
            ),
            catchError((error: HttpErrorResponse) => of(archivedIncidentsFailure({ error: error.message })))
          )
      )
    )
  );

  archivedIncidentsMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(allMarkets),
      switchMap(() =>
        this.adminMarketService.markets().pipe(
          map((response) =>
            response.result && response.message === 'Markets fetched successfully'
              ? allMarketsSuccess({ response: response.result })
              : allMarketsFailure({ error: response.message })
          ),
          catchError((error: HttpErrorResponse) => of(allMarketsFailure({ error: error.message })))
        )
      )
    )
  );

  archivedIncidentsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archivedIncidentsFilter),
      switchMap(() => [archivedIncidents({})])
    )
  );

  sendCancelUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendCancelUserRequest),
      withLatestFrom(this.store.select(selectIsLoggedInOkta)),
      switchMap(([action, isLoggedInOkta]) =>
        this.userService.sendCancelUserRequest(action.email, isLoggedInOkta).pipe(
          map((response) => {
            return response.result && response.message.includes('User account cancellation request initiated successfully.')
              ? sendCancelUserRequestSuccess()
              : sendCancelUserRequestFailure({ error: response.message });
          }),
          catchError((error) => of(sendCancelUserRequestFailure({ error: error.message })))
        )
      )
    )
  );

  sendCancelUserRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendCancelUserRequestFailure),
        tap((action) => console.error(action.error)),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  deleteUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserAccount),
      mergeMap((action) =>
        this.userService.deleteUserAccount(action.token, action.email).pipe(
          map((response) =>
            response.result && response.message === 'Send Delete Request submitted successfully'
              ? deleteUserAccountSuccess()
              : deleteUserAccountFailure({ error: response.message })
          ),
          catchError((error) => of(deleteUserAccountFailure({ error: error.message })))
        )
      )
    )
  );

  deleteUserAccountFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteUserAccountFailure),
        tap((action) => console.error(action.error)),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      withLatestFrom(this.store.select(selectloggedInUserId)),
      switchMap(([_action, selectloggedInUserId]) =>
        this.userService.getUserProfile(selectloggedInUserId).pipe(
          map((response) =>
            response.result && response.message === 'User details fetched successfully'
              ? loadUserProfileSuccess({ profile: response.result })
              : loadUserProfileFailure({ error: response.message })
          ),
          catchError((error) => of(loadUserProfileFailure({ error: error.message })))
        )
      )
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfile),
      switchMap((action) =>
        this.userService.updateUserProfile(action.profile).pipe(
          map((response) => updateUserProfileSuccess({ profile: response.result })),
          catchError((error) => of(updateUserProfileFailure({ error })))
        )
      )
    )
  );

  updateUserProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserProfileSuccess),
        tap(() => this.router.navigate(['/'])),
        tap(() =>
          this.snackBar.open('User updated successfully', 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'success-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );

  updateUserProfileFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserProfileFailure),
        tap((action) => console.error(action.error)),
        tap((action) =>
          this.snackBar.open(action.error, 'X', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'error-snackbar',
            duration: 5000,
          })
        )
      ),
    { dispatch: false }
  );
}
