import { createSelector } from '@ngrx/store';
import { selectAdminState } from '../reducers';

export const selectSelectedMarket = createSelector(selectAdminState, (state) => state.selectedMarket);
export const selectMarketProcessing = createSelector(selectAdminState, (state) => state.marketProcessing);
export const selectAdminMarkets = createSelector(selectAdminState, (state) => state.adminMarkets);

export const selectSelectedIncident = createSelector(selectAdminState, (state) => state.selectedIncident);

export const selectAdminIncidents = createSelector(selectAdminState, (state) => state.adminIncidents);

export const selectAdminMarketsOption = createSelector(selectAdminState, (state) => {
  const titles = state.adminMarkets.filter((p) => p.published === true).map((el) => el.marketTitle);
  const result = titles.map((el) => ({ key: el, name: el }));
  if (state.selectedIncident && state.selectedIncident.marketTitle && !titles.includes(state.selectedIncident.marketTitle)) {
    return [{ key: state.selectedIncident.marketTitle, name: state.selectedIncident.marketTitle }, ...result];
  }
  return result;
});

export const selectAdminTemplatesOption = createSelector(selectAdminState, (state) => {
  const titles = state.adminMarketTemplates.map((el) => el.templateTitle);
  const result = titles.map((el) => ({ key: el, name: el }));
  if (state.selectedIncident && state.selectedIncident.templateTitle && !titles.includes(state.selectedIncident.templateTitle)) {
    return [{ key: state.selectedIncident.templateTitle, name: state.selectedIncident.templateTitle }, ...result];
  }
  return result;
});

export const selectAdminMarketsOptionWithAny = createSelector(selectAdminMarketsOption, (options) => [
  { key: 'all', name: 'All Markets' },
  ...options,
]);
export const selectAdminMarketTemplates = createSelector(selectAdminState, (state) => state.adminMarketTemplates);
export const selectIncidentProcessing = createSelector(selectAdminState, (state) => state.incidentProcessing);
export const selectIncidentFilter = createSelector(selectAdminState, (state) => state.adminIncidentFilter);
export const selectIncidentsPage = createSelector(selectAdminState, (state) => state.incidentsPage[1] - 1);
export const selectIncidentsPageSize = createSelector(selectAdminState, (state) => state.incidentsPageSize);
export const selectIncidentsTotal = createSelector(selectAdminState, (state) => state.incidentsTotal);
export const selectAdminIncidentsRequest = createSelector(selectAdminState, (state) => ({
  lastEvaluatedKey: state.incidentsKey,
  lastEvaluatedIndex: state.incidentsKeyIndex,
  pageSize: state.incidentsPageSize,
  currentPageNumber: state.incidentsPage,
  isFilterChanged: state.incidentsFilterChanged,
  filters: {
    filterDate: state.adminIncidentFilter?.date ?? null,
    filterStatus: state.adminIncidentFilter?.status ?? null,
    incidentTitle: state.adminIncidentFilter?.title ?? null,
    marketTitle: state.adminIncidentFilter?.market ?? null,
  },
}));

export const selectSelectedTemplate = createSelector(selectAdminState, (state) => state.selectedTemplate);
export const selectTemplateProcessing = createSelector(selectAdminState, (state) => state.templateProcessing);
export const selectAdminTemplates = createSelector(selectAdminState, (state) => state.adminTemplates);
export const selectTemplatesPage = createSelector(selectAdminState, (state) => state.templatesPage[1] - 1);
export const selectTemplatesPageSize = createSelector(selectAdminState, (state) => state.templatesPageSize);
export const selectTemplatesTotal = createSelector(selectAdminState, (state) => state.templatesTotal);
export const selectWams = createSelector(selectAdminState, (state) => state.wams);
export const selectSelectedWam = createSelector(selectAdminState, (state) => state.selectedWam);
export const selectWamProcessing = createSelector(selectAdminState, (state) => state.wamProcessing);
export const selectAdminTemplatesRequest = createSelector(selectAdminState, (state) => ({
  lastEvaluatedKey: state.templatesKey,
  lastEvaluatedIndex: state.templatesKeyIndex,
  pageSize: state.templatesPageSize,
  currentPageNumber: state.templatesPage,
}));
